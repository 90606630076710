<template>
    <BaseButton
        :component="component"
        :disabled="isLoading || isDisabled"
        :type="btnType"
        class="button focus:outline-none focus:border-transparent focus:ring-primary-50"
        :class="[
            {
                'px-3 py-2.5': hasPadding,
                loading: isLoading
            },
            btnAlt === 'default' &&
                'border border-transparent text-white bg-primary-600 hover:bg-primary-700 active:bg-primary-700 focus:bg-primary-700  disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 focus:ring-2',
            btnAlt === 'clear-full' &&
                'text-slate-700 hover:bg-slate-200 focus:outline-none focus:ring-0 focus:text-primary-500',
            btnAlt === 'clear-full-no-focus' &&
                'text-slate-700 hover:bg-slate-200 focus:border-inherit focus:ring-transparent focus-ring-0',
            btnAlt === 'clear-full-no-hover' &&
                'text-slate-700 !bg-transparent hover:bg-transparent active:bg-transparent focus:ring-transparent focus:ring-2',
            btnAlt ? 'button--' + btnAlt : 'button--default',
            btnStyle,
            btnSize ? 'button--' + btnSize : ''
        ]"
        v-bind="$attrs"
    >
        <template #icon>
            <slot v-if="$slots.icon" name="icon" />
        </template>
        <slot />
    </BaseButton>
</template>

<script>
import BaseButton from "../../common/global/BaseButton.vue";
export default {
  name: "ChargemapButton",
  components: { BaseButton },
  props: {
    component: {
      type: String,
      default: "button"
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    btnStyle: {
      type: String,
      default: ""
    },
    btnType: {
      type: String,
      default: "button"
    },
    btnSize: {
      type: String,
      default: "base"
    },
    btnAlt: {
      type: String,
      default: "default",
      validator(value) {
        return [
          "default",
          "clear",
          "clear-white",
          "clear-full",
          "clear-full-no-hover",
          "clear-full-no-focus",
          "white",
          "white-command",
          "green",
          "alert",
          "alert--clear",
          "alert--clear-full"
        ].includes(value);
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.button {
  @apply inline-flex items-center text-base leading-6 font-medium rounded-lg transition ease-in-out duration-150;
}
.button.loading {
  @apply bg-slate-200 hover:bg-slate-400;
}
.button:disabled {
  @apply bg-slate-200 text-slate-400 border-slate-200;
}
.button:disabled:hover {
  @apply bg-slate-400 text-white cursor-not-allowed;
}
.button:disabled:hover.loading {
  cursor: progress;
}
.button__container {
  @apply inline-flex w-full items-center justify-center;
}
.button--clear {
  @apply border border-slate-300 text-slate-500 bg-white;
}
.button--clear-full {
  @apply text-slate-700;
}
.button--clear-full:hover {
  @apply bg-slate-200;
}
.button--clear-full-no-hover:hover {
  @apply bg-white;
}
.button--clear-full-no-hover:focus {
  @apply ring-white;
}
.button--clear-full-no-hover:active {
  @apply bg-white;
}
.button--clear-full:disabled {
  @apply text-zinc-300 bg-white border-zinc-300;
}
.button--clear:hover {
  @apply bg-slate-200;
}
.button--clear:disabled {
  @apply bg-slate-200 text-slate-400 border-slate-200;
}
.button--clear-white {
  @apply text-white border border-white;
}
.button--white {
  @apply border border-slate-300 text-slate-700 bg-white;
}
.button--white:hover {
  @apply bg-slate-200;
}
.button--white-command {
  @apply bg-white shadow rounded-lg text-slate-500;
}
.button--white-command:hover {
  @apply bg-slate-200;
}
.button--green {
  @apply border border-green-700 text-green-700 bg-white;
}
.button--green:hover {
  @apply bg-green-50;
}
.button--alert {
  @apply bg-rose-600 text-white;
}
.button--alert:hover {
  @apply bg-red-500;
}
.button--alert--clear {
  @apply border border-red-600 bg-white text-red-600 hover:bg-rose-50;
}
.button--alert--clear-full {
  @apply text-red-600;
}
.button--alert--clear-full:hover {
  @apply bg-rose-50;
}
.button--sm {
  @apply text-sm leading-5;
}
.button--xs {
  @apply text-xs leading-3;
}
</style>
